import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';

const name = 'payment-links';
const featureName = 'paymentLinks';

export const getEmptyStateConfig = (intl, { ctaCallback }) => {
  let dummyData = getDummyData(intl);

  return {
    name,
    featureName,
    isNewLogic: true,
    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        tracking: getTrackingNameAndProperties({
          name,
          type: TYPES.ACTIVATE,
        }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              activateAbilities: ['canCreatePaymentLinks'],
            },
            output: {
              title: intl.t('payment-links.empty-state.title'),
              subtitle: intl.t('payment-links.empty-state.description'),
              ctaText: intl.t('payment-links.empty-state.cta'),
              onClick: ctaCallback,
            },
          },
        ],
      },
    },
  };
};

const getDummyData = intl => ({
  items: [
    {
      amount: {
        value: '120.00',
        currency: 'EUR',
      },
      debitorName: intl.t('payment-links.empty-state.dummy-company'),
      name: intl.t('payment-links.empty-state.dummy-company'),
      expirationDate: '2024-09-29',
      paymentInitiator: {
        resourceNumber: intl.t('payment-links.empty-state.dummy-related-1'),
      },
      paymentMethod: '',
      status: 'open',
    },
    {
      amount: {
        value: '29.99',
        currency: 'EUR',
      },
      debitorName: intl.t('payment-links.empty-state.dummy-company'),
      name: intl.t('payment-links.empty-state.dummy-company'),
      expirationDate: '2024-04-15',
      paymentInitiator: {
        resourceNumber: intl.t('payment-links.empty-state.dummy-related-2'),
      },
      paymentMethod: 'credit_card',
      status: 'paid',
    },
  ],
});
