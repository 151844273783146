export const GMI_SOLUTION_INSTANCE_STATUSES = {
  ACCOUNT_NOT_READY: 'ACCOUNT_NOT_READY',
  ADD_SUPPLIER_CANCELED: 'ADD_SUPPLIER_CANCELED',
  CANCELLED_VERIFICATION: 'CANCELLED_VERIFICATION',
  CREATED: 'CREATED',
  INPUT_REQUESTED: 'INPUT_REQUESTED',
  INPUT_REQUEST_FILLED: 'INPUT_REQUEST_FILLED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  NO_DOCUMENTS: 'NO_DOCUMENTS',
  NO_PERMISSION: 'NO_PERMISSION',
  SUPPLIER_ADDED: 'SUPPLIER_ADDED',
  SUPPLIER_UPDATED: 'SUPPLIER_UPDATED',
  TEMP_TECHNICAL_ISSUE: 'TEMP_TECHNICAL_ISSUE',
  TWO_FA_EXPIRED: 'TWO_FA_EXPIRED',
  PORTAL_NO_INVOICE_PERMISSION: 'PORTAL_NO_INVOICE_PERMISSION',

  // 2FA
  COMPLETED_VERIFICATION: 'COMPLETED_VERIFICATION',
  RESTARTED_VERIFICATION: 'RESTARTED_VERIFICATION',
} as const;

export const GMI_STATUSES = {
  CONNECTING: 'connecting',
  ACTIVE: 'active',
  FAILED: 'failed',
  ACTION_REQUIRED: 'action_required',
} as const;

export const GMI_STATUSES_SUCCESS_EVENTS = [
  GMI_SOLUTION_INSTANCE_STATUSES.LOGIN_SUCCESS,
  GMI_SOLUTION_INSTANCE_STATUSES.NO_DOCUMENTS,
  GMI_STATUSES.ACTIVE,
] as const;

export const GMI_STATUSES_NO_PERMISSION_EVENTS = [
  GMI_SOLUTION_INSTANCE_STATUSES.NO_PERMISSION,
  GMI_SOLUTION_INSTANCE_STATUSES.PORTAL_NO_INVOICE_PERMISSION,
] as const;

export const GMI_STATUSES_CONNECTING_STATUSES = [
  GMI_SOLUTION_INSTANCE_STATUSES.CREATED,
  GMI_SOLUTION_INSTANCE_STATUSES.INPUT_REQUEST_FILLED,
  GMI_SOLUTION_INSTANCE_STATUSES.SUPPLIER_ADDED,
  GMI_SOLUTION_INSTANCE_STATUSES.SUPPLIER_UPDATED,
  GMI_SOLUTION_INSTANCE_STATUSES.TEMP_TECHNICAL_ISSUE,
  GMI_SOLUTION_INSTANCE_STATUSES.COMPLETED_VERIFICATION,
  GMI_SOLUTION_INSTANCE_STATUSES.RESTARTED_VERIFICATION,
  GMI_STATUSES.CONNECTING,
] as const;

export const GMI_STATUSES_ACTION_REQUIRED = [
  GMI_SOLUTION_INSTANCE_STATUSES.INPUT_REQUESTED,
  GMI_SOLUTION_INSTANCE_STATUSES.TWO_FA_EXPIRED,
  GMI_STATUSES.ACTION_REQUIRED,
] as const;

export const GMI_STATUSES_FAILED_STATUSES = [
  GMI_SOLUTION_INSTANCE_STATUSES.LOGIN_FAILED,
  GMI_SOLUTION_INSTANCE_STATUSES.NO_PERMISSION,
  GMI_SOLUTION_INSTANCE_STATUSES.CANCELLED_VERIFICATION,
  GMI_SOLUTION_INSTANCE_STATUSES.ACCOUNT_NOT_READY,
  GMI_SOLUTION_INSTANCE_STATUSES.PORTAL_NO_INVOICE_PERMISSION,
  GMI_SOLUTION_INSTANCE_STATUSES.ADD_SUPPLIER_CANCELED,
  GMI_STATUSES.FAILED,
] as const;

export const GMI_STATUSES_VERIFICATION_STATUSES = [
  GMI_SOLUTION_INSTANCE_STATUSES.COMPLETED_VERIFICATION,
  GMI_SOLUTION_INSTANCE_STATUSES.RESTARTED_VERIFICATION,
] as const;

// Type definitions
export type GmiSolutionInstanceStatus =
  (typeof GMI_SOLUTION_INSTANCE_STATUSES)[keyof typeof GMI_SOLUTION_INSTANCE_STATUSES];
export type GmiStatus = (typeof GMI_STATUSES)[keyof typeof GMI_STATUSES];
export type GmiStatusesSuccessEvent = (typeof GMI_STATUSES_SUCCESS_EVENTS)[number];
export type GmiStatusesNoPermissionEvent = (typeof GMI_STATUSES_NO_PERMISSION_EVENTS)[number];
export type GmiStatusesConnectingStatus = (typeof GMI_STATUSES_CONNECTING_STATUSES)[number];
export type GmiStatusesActionRequired = (typeof GMI_STATUSES_ACTION_REQUIRED)[number];
export type GmiStatusesFailedStatus = (typeof GMI_STATUSES_FAILED_STATUSES)[number];
export type GmiStatusesVerificationStatus = (typeof GMI_STATUSES_VERIFICATION_STATUSES)[number];
